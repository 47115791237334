import React, { useState, useEffect } from 'react';

import PrivatePolicy from './docs/PrivatePolicy';
import Payment from './docs/Payment';
import Contract from './docs/Contract';

import jcb from '../img/jcb.svg'
import visa from '../img/visa.svg'
import mastercard from '../img/mastercard.svg'
import mir from '../img/mir.svg'
import sber from '../img/sberpay.svg'
import vk from '../img/vk.svg'
import whats from '../img/whatsapp.svg'

function Footer() {

    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    return (
        <footer className="footer">
            {width < 768 ? ( /* Проверка ширины экрана */
                <div className="footer__content">
                    <div className="footer__content__contacts">
                        <div className="footer__content__contacts__links">
                            <a href="tel:  8-800-550-2134" className="footer__content__contacts__links__link">  8 (800) 550-2134</a>
                            <a href="mailto: shine-school@yandex.ru" className="footer__content__contacts__links__link"> shine-school@yandex.ru</a>
                        </div>
                        <div className="footer__content__contacts__networks">
                            <a href="https://vk.com/shineschool" target="blank" className="footer__content__contacts__networks__item">
                                <img loading="lazy" src={vk} alt="vkontakte" className="footer__content__contacts__networks__item__img" />
                            </a>
                            <a href="https://wa.me/+79993267002" target="blank" className="footer__content__contacts__networks__item">
                                <img loading="lazy" src={whats} alt="whats" className="footer__content__contacts__networks__item__img" />
                            </a>
                        </div>
                    </div>
                    <div className="footer__content__docs">
                        <PrivatePolicy/>
                        <Payment/>
                        <Contract/>
                    </div>
                    <p className="footer__content__info">
                        Индивидуальный предприниматель <br />
                        Сидорова Ирина Леонидовна <br />
                        ОГРНИП 322554300055780 <br />
                        ИНН 550311058224
                    </p>
                    <div className="footer__content__payments">
                        <img loading="lazy" src={jcb} alt="payment" className="footer__content__payments__payment" />
                        <img loading="lazy" src={visa} alt="payment" className="footer__content__payments__payment" />
                        <img loading="lazy" src={mastercard} alt="payment" className="footer__content__payments__payment" />
                        <img loading="lazy" src={mir} alt="payment" className="footer__content__payments__payment" />
                        <img loading="lazy" src={sber} alt="payment" className="footer__content__payments__payment" />
                    </div>
                    <p className="footer__content__rights">
                        © 2023. Все права защищены | Shine School
                    </p>
                </div>
            ) : (
                <div className="footer__content">
                    <div className="footer__content__left">
                        <div className="footer__content__left__links">
                            <PrivatePolicy/>
                            <Payment/>
                            <Contract/>
                        </div>
                        <div className="footer__content__left__payments">
                            <img loading="lazy" src={jcb} alt="payment" className="footer__content__left__payments__payment" />
                            <img loading="lazy" src={visa} alt="payment" className="footer__content__left__payments__payment" />
                            <img loading="lazy" src={mastercard} alt="payment" className="footer__content__left__payments__payment" />
                            <img loading="lazy" src={mir} alt="payment" className="footer__content__left__payments__payment" />
                            <img loading="lazy" src={sber} alt="payment" className="footer__content__left__payments__payment" />
                        </div>
                        <p className="footer__content__left__rights">
                            © 2023. Все права защищены | Shine School
                        </p>
                    </div>
                    <div className="footer__content__right">
                        <div className="footer__content__right__contacts">
                            <a href="tel: 8-800-550-2134" className="footer__content__right__contacts__link"> 8 (800) 550-2134</a>
                            <a href="mailto: shine-school@yandex.ru" className="footer__content__right__contacts__link"> shine-school@yandex.ru</a>
                            <p className="footer__content__right__contacts__info">
                                Индивидуальный предприниматель <br />
                                Сидорова Ирина Леонидовна <br />
                                ОГРНИП 322554300055780 <br />
                                ИНН 550311058224
                            </p>
                        </div>
                        <div className="footer__content__right__networks">
                            <a href="https://vk.com/shineschool" target="blank" className="footer__content__right__networks__item">
                                <img loading="lazy" src={vk} alt="vkontakte" className="footer__content__right__networks__item__img" />
                            </a>
                            <a href="https://wa.me/+79993267002" target="blank" className="footer__content__right__networks__item">
                                <img loading="lazy" src={whats} alt="whats" className="footer__content__right__networks__item__img" />
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </footer>

    )
}

export default Footer;