export const Minimal = [
    {
        id: 1,
        title: 'Аудирование',
        info: [
            // Элементы массива должны быть JSX элементами
            <>
                <div className="bold">Лекция 1:</div> типы заданий по аудированию, стратегии выполнения
            </>,
            <>
                <div className="bold">Практика 1:</div> выполнение заданий с комментариями и пояснениями по стратегиям
            </>,
            <>
                <div className="bold">Домашнее задание 1:</div> изучить конспект, выполнить задания по аудированию
            </>
        ]
    },
    {
        id: 2,
        title: 'Чтение',
        info: [
            <>
                <div className="bold">Лекция 2:</div> типы заданий по чтению, стратегии выполнения
            </>,
            <>
                <div className="bold">Практика 2:</div> выполнение заданий с комментариями и пояснениями по стратегиям
            </>,
            <>
                <div className="bold">Домашнее задание 2:</div> изучить конспект, выполнить задания по чтению

            </>
        ]
    },
    {
        id: 3,
        title: 'Лексика и грамматика',
        info: [
            <>
                <div className="bold">Лекция 3:</div> типы заданий, стратегии выполнения <br/> Материал с необходимыми грамматическими конструкциями + правила словообразования
            </>,
            <>
                <div className="bold">Практика 3:</div> выполнение заданий с комментариями и пояснениями по стратегиям
            </>,
            <>
                <div className="bold">Домашнее задание 3:</div> изучить конспект, выполнить задания по грамматике и лексике
            </>
        ]
    },
    {
        id: 4,
        title: 'Письмо',
        info: [
            <>
                <div className="bold">Лекция 4:</div> задание, стратегии выполнения, обязательные фразы <br/> Обсуждение критериев оценивания
            </>,
            <>
                <div className="bold">Практика 4:</div> выполнение задания с комментариями и пояснениями по стратегиям <br/> Построение mind map
            </>,
            <>
                <div className="bold">Домашнее задание 4:</div> изучить конспект, выполнить письменное задание
            </>
        ]
    },
    {
        id: 5,
        title: 'Устная часть',
        info: [
            <>
                <div className="bold">Лекция 5:</div> типы заданий, стратегии выполнения, обязательные фразы <br/> Обсуждение критериев оценивания
            </>,
            <>
                <div className="bold">Практика 5:</div> выполнение заданий с комментариями
            </>,
            <>
                <div className="bold">Домашнее задание 5:</div> изучить конспект, выполнить задания по устной части
            </>
        ]
    },
    // {
    //     id: 6,
    //     title: 'Финальный тест',
    //     info: [
    //         <>
    //             <div className="inline">Интерактивный тест на платформе</div> 
    //         </>,
    //     ]
    // },
];



export const Optimal = [
    {
        id: 1,
        title: 'Темы уроков по лексике:',
        info: [
            <div className="column">
                <p className="column__text">
                    1. Семья и друзья. Внешность и характер человека. Обязанности по дому. 
                </p>
                <p className="column__text">
                    2. Досуг и хобби современного подростка (чтение, кино, театр, музыка, музей, спорт, живопись, компьютерные игры). 
                </p>
                <p className="column__text">
                    3. Здоровый образ жизни: режим труда и отдыха, фитнес, сбалансированное питание, посещение врача.
                </p>
                <p className="column__text">
                    4. Покупки: одежда, обувь и продукты. Деньги и мода. 
                </p>
                <p className="column__text">
                    5. Школа, школьная жизнь, предметы, взаимоотношения в школе.
                </p>
                <p className="column__text">
                    6. Мир современных профессий.
                </p>
                <p className="column__text">
                    7. Виды отдыха, каникулы и путешествия.
                </p>
                <p className="column__text">
                    8. Жизнь в городе и в сельской местности. 
                </p>
                <p className="column__text">
                    9. Природа, проблемы экологии, защита окружающей среды.
                </p>
                <p className="column__text">
                    10. СМИ (телевидение, радио, пресса, Интернет)
                </p>
                <p className="column__text">
                    11. Родная страна и страны изучаемого языка. Их география, культура.
                </p>
                <p className="column__text">
                    12. Выдающиеся люди родной страны и стран изучаемого языка.
                </p>
            </div>
            
        ]
    },
    {
        id: 2,
        title: 'Темы уроков по грамматике:',
        info: [
            <div className="column">
                <p className="column__text">
                    1. Предложения с глаголом-связкой to be, начальным There + to be, It. Порядок слов в вопросе, краткий ответ. Сложноподчинённые предложения с who, which, that.
                </p>
                <p className="column__text">
                    2. Личные и притяжательные местоимения, указательные местоимения. Множественное число существительных и собирательные существительные.
                </p>
                <p className="column__text">
                    3. Основные времена: Present/Past/Future Simple Tense; Present/Past Continuous Tense; Present/Past Perfect Tense. 
                </p>
                <p className="column__text">
                    4. Порядок слов в утвердительном, отрицательном и вопросительном предложении. Все типы вопросов в Present/Past/Future Simple Tense; Present/Past Continuous Tense; Present/Past Perfect Tense.
                </p>
                <p className="column__text">
                    5. Согласование времен в сложном предложении. Косвенная речь
                </p>
                <p className="column__text">
                    6. Пассивный залог: глаголы в наиболее употребительных формах (Present/ Past Simple Passive, Present Perfect Passive)
                </p>
                <p className="column__text">
                    7. Условные предложения реального характера (Conditional 0, Conditional I) и нереального характера (Conditional II). Конструкция I wish…
                </p>
                <p className="column__text">
                    8. Инфинитив, герундий, причастия настоящего и прошедшего времени
                </p>
                <p className="column__text">
                    9. Модальные глаголы и их эквиваленты (can/be able to, must/have to, may, might, should, need) в настоящем и прошедшем времени.
                </p>
                <p className="column__text">
                    10. Степени сравнения прилагательных и наречий. Предложения с конструкциями as… as, not so… as
                </p>
                <p className="column__text">
                    11. Конструкции used to + инфинитив глагола, be/get used to + инфинитив глагола, be/get used to doing something, be/get used to something
                </p>
                <p className="column__text">
                    12. Конструкция both… and…; either… or, neither… nor; I prefer… / I'd prefer… / I'd rather…
                </p>
            </div>
        ]
    },
    {
        id: 3,
        title: '',
        info: [
            <>
                <div className="inline">Интерактивное домашнее задание на платформе с проверкой по каждой теме.</div> 
            </>,
        ]
    },
];



export const Premium = [
    {
        id: 6,
        // title: 'Все содержание пакета Минимальный + Оптимальный',
        info: [
            <>
                <div className="inlinePremium">По каждой паре блоков Лексика + Грамматика назначаем индивидуальное занятие с педагогом для детальной отработки полученного материала (всего 12 шт). </div> 
            </>,
        ]
    },
];
