import React, { useState, useEffect } from 'react';

import PaymentForm from './payForm';

import dialog7 from '../img/dialog7.svg'
import additionalImg from '../img/date-img.svg'
import additional from '../img/additional.svg'
import additionalMobile from '../img/additionalMobile.svg'


function Additional(){

    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    return(
        <div className="main__section__additional" id="additional">
            {width > 1024 ? ( /* Проверка ширины экрана */
                <div className="main__section__additional__box">
                    <div className="main__section__additional__box__container">
                        {/* <div className="main__section__additional__box__container__question">
                            <img src={dialog7} alt="img" className="main__section__additional__box__container__question__box"/>
                            <p className="main__section__additional__box__container__question__text" >
                                Хочешь быть уверен, что готов к экзамену? <br/> Рассмотри дополнительные услуги
                            </p>
                        </div> */}
                        <div className="main__section__diagnostic__question">
                            <p className="main__section__diagnostic__question__text" >
                                Хочешь быть уверен, что готов к экзамену? <br/> Рассмотри дополнительные услуги
                            </p>
                            <div className="main__section__diagnostic__question__border"></div>
                            <div className="main__section__diagnostic__question__layout"></div>
                        </div>
                        <img src={additionalImg} alt="img" className="main__section__additional__box__container__img"/>
                    </div>
                    {/* <form action="https://securecardpayment.ru/sc/yCwkKsRZzERlkCPm" target="blank">
                        <div className="main__section__additional__box__button">
                            <button className="main__section__additional__box__button__hover">
                                приобрести услуги
                            </button>
                        </div>
                    </form> */}
                    <div>
                        <img src={additional} alt="img" className="main__section__additional__box__info"/>
                        {/* <form action="https://securecardpayment.ru/sc/yCwkKsRZzERlkCPm" target="blank"> */}
                        {/* <div className="main__section__additional__box__button"> */}
                        <div className="main__section__additional__box__button" data-hystmodal='#paymentform'>
                            <button className="main__section__additional__box__button__hover">
                                приобрести услуги
                            </button>
                        </div>
                    {/* </form> */}
                    </div>
                    
                </div>
            ) : (
                <div className="main__section__additional__box">
                    {/* <div className="main__section__additional__box__question">
                        <img src={dialog7} alt="img" className="main__section__additional__box__question__box"/>
                        <p className="main__section__additional__box__question__text" >
                            Хочешь быть уверен, что готов к экзамену? <br/> Рассмотри дополнительные услуги
                        </p>
                    </div> */}
                    <div className="main__section__diagnostic__question">
                        <p className="main__section__diagnostic__question__text" >
                            Хочешь быть уверен, что готов к экзамену? Рассмотри дополнительные услуги
                        </p>
                        <div className="main__section__diagnostic__question__border"></div>
                        <div className="main__section__diagnostic__question__layout"></div>
                    </div>
                    <img src={additionalImg} alt="img" className="main__section__additional__box__img"/>
                    <div className="main__section__additional__box__container">
                        {/* <img src={additionalImg} alt="img" className="main__section__additional__box__container__img"/> */}
                        {/* <div> */}
                            <img src={additionalMobile} alt="img" className="main__section__additional__box__container__info"/>
                            <form action="https://securecardpayment.ru/sc/yCwkKsRZzERlkCPm" target="blank">
                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button">
                                    <button className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover">
                                        приобрести услуги
                                    </button>
                                </div>
                            </form>
                        {/* </div> */}
                        
                    </div>
                </div>
            )}
        </div>
    )
}

export default Additional;