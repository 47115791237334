import React, { useState } from "react";

import { StrategiesInfo } from "./StrategiesInfo";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

function StrategiesCard() {
    
    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
        // настройки (не обязательно), см. API
    });


    return(
        <div>
            {StrategiesInfo.map((data, key) => {
                return (
                    <div className="main__section__strategies__popUp hystmodal" id={data.modal} aria-hidden="true">
                        <div key={key} className="main__section__strategies__popUp__modal hystmodal__wrap">
                            <div className="main__section__strategies__popUp__modal__content hystmodal__window window__teacher" role="dialog" aria-modal="true">
                                <button data-hystclose className="hystmodal__close">Закрыть</button>
                                {/* <img src={data.info} alt="img" className="main__section__teachers__popUp__modal__content__strategiesImg"/> */}
                                <Swiper
                                    className="main__section__strategies__popUp__modal__content__slider"
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    centeredSlides= {true}
                                    navigation
                                    // autoplay={{ delay: 3000 }}
                                    // loop
                                    pagination={{ clickable: true }}
                                >
                                    {data.info && data.info.map((info, index) => (
                                        <SwiperSlide key={index} className="main__section__strategies__popUp__modal__content__slider__slide">
                                            <img loading="lazy" src={info} alt="img" className="main__section__strategies__popUp__modal__content__slider__slide__img" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default StrategiesCard;