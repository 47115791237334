import React from "react";

import LeadForm from "./form";
import PaymentForm from "./payForm";

import Home from "./Homepage";
import Diagnostic from "./Diagnostic";
import Strategies from "./Strategies";
import Rates from "./Rates";
import Date from "./Date";
import Developers from "./Developers";
import Teachers from "./Teachers";
import Additional from "./Additional";
import homeImg from '../img/homepage.svg'
import diagnosticStudent from '../img/additionalImg.svg'



// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/autoplay';
// import 'swiper/css/effect-cards';

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

// import waves from '../img/waves.svg'
// import waves5 from '../img/waves5.svg'
// import china from '../img/chinaicon.svg'
// import gradient from '../img/gradient.svg'
import up from '../img/up.svg'
import wave1 from '../img/waves.svg'
import background from '../img/backgroundLines.svg'




function Main() {
    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
    });

    const handleUpClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Добавляем плавное скроллирование
        });
    }
    return (
        <main className="main">
            <img src={up} onClick={handleUpClick} alt="logo" id="pointer" className="main__up" />
            <div className="main__choice hystmodal" id='profile' aria-hidden="true">
                <div className="main__choice__modal hystmodal__wrap">
                    <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__choice__modal__layout__content">
                            <h1 className="main__choice__modal__layout__content__title">Выбрать другой профиль обучения:</h1>
                            <a href="https://my-english.club/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать английский язык</a>
                            <a href="https://chinese.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать китайский язык</a>
                            <a href="https://kids.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Меня интересует дошкольное обучение</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="main__choice hystmodal" id='profile' aria-hidden="true">
                <div className="main__choice__modal hystmodal__wrap">
                    <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__choice__modal__layout__content">
                            <h1 className="main__choice__modal__layout__content__title">Выбрать другой профиль обучения:</h1>
                            <a href="https://my-english.club/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать английский язык</a>
                            <a href="https://chinese.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать китайский язык</a>
                            <a href="https://kids.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Меня интересует дошкольное обучение</a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="main__section__form hystmodal" id='form' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <LeadForm/>
                    </div>
                </div>
            </div>
            <div className="main__section__form hystmodal" id='paymentform' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <PaymentForm/>
                    </div>
                </div>
            </div>
            <section className="main__section visible-border">
                <div className="main__section__home__picture">
                    <img src={homeImg} alt="img" className="main__section__home__picture__img" />
                </div>
                <Home/>
            </section>
            <section className="main__section">
                <Diagnostic/>
            </section>
            <section className="main__section">
                <Strategies/>
            </section>
            <section className="main__section">
                <Rates/>
            </section>
            <section className="main__section">
                <img src={background} alt="img" className="main__section__date__background"/>
                <Date/>
            </section>
            <section className="main__section visible-border">
                <img src={background} alt="img" className="main__section__date__backgroundTeachers"/>
                <Developers/>
            </section>
            <section className="main__section">
                <Additional/>
            </section>
            <section className="main__section">
                <Teachers/>
            </section>
        </main>
    )
}


export default Main;