import React from 'react';

import jcb from '../../img/jcb.svg'
import visa from '../../img/visa.svg'
import mastercard from '../../img/mastercard.svg'
import mir from '../../img/mir.svg'

function Payment() {
    return (
        <>
            <div
                data-hystmodal='#Payment'
                className="footer__content__left__links__link"
                id="pointer"
            >
                Оплата
            </div>
            <div className="main__docs hystmodal" id='Payment' aria-hidden="true">
                <div className="main__docs__modal hystmodal__wrap">
                    <div className="main__docs__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__docs__modal__layout__content">
                            <h1 className="main__docs__modal__layout__content__title">Способы оплаты и безопасность</h1>
                            <p className="main__docs__modal__layout__content__ol__li">Способы оплаты</p>
                            <ol className="main__docs__modal__layout__content__ol">
                                <li className="main__docs__modal__layout__content__ol__li">
                                    Дистанционно.
                                    <ul className="main__docs__modal__layout__content__ol__li__ul">
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
                                        </li>
                                        <p className="main__docs__modal__layout__content__ol__li__ul__ways">
                                            <img loading="lazy" src={jcb} alt="payment " className="main__docs__modal__layout__content__ol__li__ul__ways__img" />
                                            <img loading="lazy" src={visa} alt="payment " className="main__docs__modal__layout__content__ol__li__ul__ways__img" />
                                            <img loading="lazy" src={mastercard} alt="payment " className="main__docs__modal__layout__content__ol__li__ul__ways__img" />
                                            <img loading="lazy" src={mir} alt="payment " className="main__docs__modal__layout__content__ol__li__ul__ways__img" />
                                        </p>
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод специального пароля.
                                        </li>
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
                                        </li>
                                    </ul>
                                </li>
                                <li className="main__docs__modal__layout__content__ol__li">
                                    Банковский перевод
                                    <ul className="main__docs__modal__layout__content__ol__li__ul">
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            ИП Сидорова Ирина Леонидовна <br />
                                            ИНН 550311058224<br />
                                            Фактический адрес: Российская Федерация, 644074, г. Омск, ул. Ватутина, д. 22А, кв. 43<br />
                                            Реквизиты банка: р/с 40802810045000100179 в ПАО Сбербанк, к/с 30101810900000000673
                                            БИК 045209673
                                        </li>
                                    </ul>
                                    <ul className="main__docs__modal__layout__content__ol__li__ul">
                                        <p className="main__docs__modal__layout__content__ol__li">
                                            В соответствии с ФЗ «О защите прав потребителей» в случае, если Вам оказана услуга ненадлежащего качества, платеж может быть возвращен на банковскую карту, с которой производилась оплата.
                                        </p>
                                        <p className="main__docs__modal__layout__content__ol__li">
                                            Способы возврата денег:
                                        </p>
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            Если вы оплачивали денежным переводом, то возврат может быть произведён на банковскую карту или в виде взаимозачёта за другую услугу;
                                        </li>
                                        <li className="main__docs__modal__layout__content__ol__li__ul__li">
                                            Если вы оплачивали с банковской карты, то возврат может быть произведёт только на ту же самую банковскую карту.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment;