import React from "react";

import StrategiesCard from "./StrategiesCard";
import { StrategiesInfo } from "./StrategiesInfo";

import dateImg from '../img/additionalImg.svg'
import dialog3 from '../img/dialog3.svg'
import dialog4 from '../img/dialog4.svg'
import listening from '../img/listening.svg'
import reading from '../img/reading.svg'
import grammar from '../img/grammar.svg'
import mail from '../img/mail.svg'
import speaking from '../img/speaking.svg'



function Strategies(){
    return(
        <div className="main__section__strategies">
            <p className="main__section__strategies__text">
                Это структура и эффективные стратегии выполнения всех разделов ОГЭ:
            </p>
            <div className="main__section__strategies__wrapper">
                {StrategiesInfo.map((data, key) => {
                    return (
                            <div key={key} className="main__section__strategies__wrapper__point" data-hystmodal={"#" + data.modal} id="pointer">
                                <img src={data.img} alt="img" className="main__section__strategies__wrapper__point__img"/>
                                <p className="main__section__strategies__wrapper__point__text"  id="pointer">
                                    {data.title}
                                </p>
                            </div>
                            // {/* <button className="main__section__strategies__wrapper__point" data-hystmodal={"#" + data.modal}>
                            //     <img src={data.img} alt="img" className="main__section__strategies__wrapper__point__img"/>
                            //     <p className="main__section__strategies__wrapper__point__text">
                            //         {data.title}
                            //     </p>
                            // </button> */}
                            // {/* <div className="main__section__strategies__wrapper__point" data-hystmodal={"#" + data.modal}>
                            //     <img src={reading} alt="img" className="main__section__strategies__wrapper__point__img"/>
                            //     <p className="main__section__strategies__wrapper__point__text">
                            //         Чтение
                            //     </p>
                            // </div>
                            // <div className="main__section__strategies__wrapper__point" data-hystmodal={"#" + data.modal}>
                            //     <img src={grammar} alt="img" className="main__section__strategies__wrapper__point__img"/>
                            //     <p className="main__section__strategies__wrapper__point__text">
                            //         Грамматика и лексика
                            //     </p>
                            // </div>
                            // <div className="main__section__strategies__wrapper__point">
                            //     <img src={mail} alt="img" className="main__section__strategies__wrapper__point__img"/>
                            //     <p className="main__section__strategies__wrapper__point__text">
                            //         Письмо
                            //     </p>
                            // </div>
                            // <div className="main__section__strategies__wrapper__point">
                            //     <img src={speaking} alt="img" className="main__section__strategies__wrapper__point__img"/>
                            //     <p className="main__section__strategies__wrapper__point__text">
                            //         Говорение
                            //     </p>
                            // </div> */}
                        )
                    }
                )}
            </div>
            {/* <div className="main__section__strategies__wrapper">
                <button className="main__section__strategies__wrapper__point">
                    <img src={listening} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Аудирование
                    </p>
                </button>
                <div className="main__section__strategies__wrapper__point" data-hystmodal='#strategies'>
                    <img src={listening} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Аудирование
                    </p>
                </div>
                <div className="main__section__strategies__wrapper__point">
                    <img src={reading} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Чтение
                    </p>
                </div>
                <div className="main__section__strategies__wrapper__point">
                    <img src={grammar} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Грамматика и лексика
                    </p>
                </div>
                <div className="main__section__strategies__wrapper__point">
                    <img src={mail} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Письмо
                    </p>
                </div>
                <div className="main__section__strategies__wrapper__point">
                    <img src={speaking} alt="img" className="main__section__strategies__wrapper__point__img"/>
                    <p className="main__section__strategies__wrapper__point__text">
                        Говорение
                    </p>
                </div>
            </div> */}
            <div className="main__section__strategies__date">
                <div className="main__section__diagnostic__question">
                    <p className="main__section__diagnostic__question__text" >
                    {/* Если не владеешь стратегиями выполнения этих 5 разделов, результат не будет высоким. Можно даже завалить экзамен. */}
                    Если не знаешь формат и стратегии для 5 разделов, то можно завалить экзамен... Даже если уровень неплохой
                    </p>
                    <div className="main__section__diagnostic__question__border"></div>
                    <div className="main__section__diagnostic__question__layout"></div>
                </div>
                <div className="main__section__diagnostic__answer">
                    <div className="main__section__diagnostic__question__border"></div>
                    <div className="main__section__diagnostic__question__layout"></div>
                    <p className="main__section__diagnostic__answer__text" >
                        Что делать?
                    </p>
                </div>
                <img src={dateImg} alt="img" className="main__section__strategies__date__img"/>
                {/* <p className="main__section__strategies__dateSubtitle">
                    Если не владеешь стратегиями выполнения этих 5 разделов, результат не будет высоким. Можно даже завалить экзамен.
                </p> */}
                {/* <div className="main__section__strategies__date"> */}
                    {/* <h3 className="main__section__strategies__date__title">
                        Если не владеешь стратегиями выполнения этих 5 блоков, результат не будет высоким. Можно даже завалить экзамен.
                    </h3> */}
                    {/* <img src={dateImg} alt="img" className="main__section__strategies__date__img"/> */}
                    {/* <div className="main__section__strategies__date__box">
                        <div className="main__section__strategies__date__box__question" >
                            <img src={dialog3} alt="img" className="main__section__strategies__date__box__question__img"/>
                            <p className="main__section__strategies__date__box__question__text" >
                                Что делать?
                            </p>
                        </div>
                        <div className="main__section__strategies__date__box__answer" >
                            <img src={dialog4} alt="img" className="main__section__strategies__date__box__answer__img"/>
                            <p className="main__section__strategies__date__box__answer__text" >
                                Хорошая новость: <br/> ты успеваешь подготовиться и получить<br/> максимальный результат! <br/> Приходи на нашу экспресс-программу.
                                <p className="main__section__strategies__date__box__answer__text__date" >
                                    Сроки: с 4.03  по 31.03 2024 гг.
                                </p>
                            </p>
                        </div>
                    </div> */}
                    <div className="main__section__diagnostic__question">
                        <p className="main__section__diagnostic__question__text" >
                            Хорошая новость: <br/> ты успеваешь подготовиться и получить<br/> максимальный результат! <br/> Приходи на наш интенсивный курс ОГЭ&nbsp;Turbo&nbsp;English.
                            <p className="main__section__diagnostic__question__text__time" >
                                Сроки: с 7.10  по 29.12 2024 гг.
                            </p>
                        </p>
                        <div className="main__section__diagnostic__question__border"></div>
                        <div className="main__section__diagnostic__question__layout"></div>
                    </div>
                {/* </div> */}
            </div>
            <StrategiesCard/>
        </div>
    )
}

export default Strategies;