import React, { useState, useEffect } from 'react';
import homeImg from '../img/homepage.svg'


function Home(){
    return(
        <div className="main__section__home">
            <div className="main__section__home__content">
                <div className="main__section__home__content__text">
                    <h1 className="main__section__home__content__text__h1">
                        Интенсивный курс ОГЭ&nbsp;Turbo&nbsp;English
                    </h1>
                    <div className="main__section__home__content__text__simple">
                        {/* <h3 className="main__section__home__content__text__simple__h3">
                            Буду сдавать ОГЭ по английскому, там&nbsp;все&nbsp;легко!
                        </h3> */}
                        <h3 className="main__section__home__content__text__simple__p">
                            {/* А ты знаешь свой уровень английского?  */}
                            Пройди тест по английскому языку за&nbsp;10&nbsp;минут и узнай свой уровень 
                            <div className="main__section__diagnostic__answer__buttonContainer">
                                <a href="https://student.shineschool.ru/home/assessment/4630234?slug=DmN7W0AW&onlypreview=true" target="_blank">
                                    <button className="main__section__diagnostic__answer__buttonContainer__button">
                                        готов ли ты к ОГЭ?
                                    </button>
                                </a>
                            </div>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;