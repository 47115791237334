import listening from '../img/listening.svg'
import reading from '../img/reading.svg'
import grammar from '../img/grammar.svg'
import mail from '../img/mail.svg'
import speaking from '../img/speaking.svg'
import listeningInfo1 from '../img/demo/listening1.webp'
import listeningInfo2 from '../img/demo/listening2.webp'
import listeningInfo3 from '../img/demo/listening3.webp'
import listeningInfo4 from '../img/demo/listening4.webp'

import readingInfo from '../img/teachers/teacher-2.webp'
import readingInfo1 from '../img/demo/reading1.webp'
import readingInfo2 from '../img/demo/reading2.webp'
import readingInfo3 from '../img/demo/reading3.webp'
import readingInfo4 from '../img/demo/reading4.webp'
import readingInfo5 from '../img/demo/reading5.webp'
import readingInfo6 from '../img/demo/reading6.webp'

import grammarInfo1 from '../img/demo/grammar1.webp'
import grammarInfo2 from '../img/demo/grammar2.webp'
import grammarInfo3 from '../img/demo/grammar3.webp'

import writingInfo1 from '../img/demo/writing.webp'

import speakingInfo1 from '../img/demo/speaking1.webp'
import speakingInfo2 from '../img/demo/speaking2.webp'
import speakingInfo3 from '../img/demo/speaking3.webp'

export const StrategiesInfo = [
    {
        id: 1,
        img: listening,
        modal: 'listening',
        title: 'Аудирование',
        info: [
            listeningInfo1,
            listeningInfo2,
            listeningInfo3,
            listeningInfo4,
        ]
    },
    {
        id: 2,
        img: reading,
        modal: 'reading',
        title: 'Чтение',
        info: [
            readingInfo1,
            readingInfo2,
            readingInfo3,
            readingInfo4,
            readingInfo5,
            readingInfo6,
        ]
    },
    {
        id: 3,
        img: grammar,
        modal: 'grammar',
        title: 'Лексика и грамматика',
        info: [
            grammarInfo1,
            grammarInfo2,
            grammarInfo3
        ]
    },
    {
        id: 4,
        img: mail,
        modal: 'writing',
        title: 'Письмо',
        info: [
            writingInfo1
        ]
    },
    {
        id: 5,
        img: speaking,
        modal: 'speaking',
        title: 'Говорение',
        info: [
            speakingInfo1,
            speakingInfo2,
            speakingInfo3
        ]
    }
];
