import React from "react";

import dialog1 from '../img/dialog1.svg'
import dialog2 from '../img/dialog2.svg'
// import teacher from '../img/teacher.svg'
// import teacher from '../img/teacher1.svg'
import teacher from '../img/teacher3.svg'
// import teacher from '../img/teacher4.svg'
import studentImg from '../img/student.svg'

import student from '../img/additionalImg.svg'

function Diagnostic(){
    return(
        <div className="main__section__diagnostic">
            {/* <div className="main__section__diagnostic__question">
                <div className="main__section__diagnostic__question__border"></div>
                <div className="main__section__diagnostic__question__layout"></div>
                <p className="main__section__diagnostic__answer__text" >
                    А ты знаешь свой уровень английского? Пройди тест за 10 минут и узнай примерную оценку
                </p>
                <div className="main__section__diagnostic__answer__buttonContainer">
                    <a href="https://student.shineschool.ru/home/assessment/4630234?slug=DmN7W0AW&onlypreview=true" target="_blank">
                        <button className="main__section__diagnostic__answer__buttonContainer__button">
                            готов ли ты к ОГЭ?
                        </button>
                    </a>
                </div>
            </div> */}
            {/* <div className="main__section__diagnostic__question">
                <div className="main__section__diagnostic__question__border"></div>
                <div className="main__section__diagnostic__question__layout"></div>
                <p className="main__section__diagnostic__answer__text" >
                    А ты знаешь свой уровень английского? 
                </p>
            </div> */}
           {/* <div className="main__section__home__content__text">
                <div className="main__section__home__content__text__simple">
                    <h3 className="main__section__home__content__text__simple__h3">
                        Пройди тест по английскому за 10 минут и узнай свой уровень английского
                    </h3>
                    <div className="main__section__diagnostic__answer__buttonContainer">
                        <a href="https://student.shineschool.ru/home/assessment/4630234?slug=DmN7W0AW&onlypreview=true" target="_blank">
                            <button className="main__section__diagnostic__answer__buttonContainer__button">
                                готов ли ты к ОГЭ?
                            </button>
                        </a>
                    </div>
                </div>
            </div> */}
            <div className="main__section__diagnostic__speech">
                <img src={teacher} alt="img" className="main__section__diagnostic__teacherImg"/> 
                <div className="main__section__diagnostic__question main__section__diagnostic__speech__question">
                    <p className="main__section__diagnostic__question__text" >
                        Прошел тест? <br/> Если уровень Pre-Intermediate (оценка&nbsp;4), то тебе достаточно понять формат ОГЭ
                    </p>
                    <div className="main__section__diagnostic__question__border"></div>
                    <div className="main__section__diagnostic__question__layout"></div>
                </div>
            </div>
            <div className="main__section__diagnostic__speechStudent">
                <div className="main__section__diagnostic__answer main__section__diagnostic__speechStudent__answer">
                    <p className="main__section__diagnostic__question__text" >
                        Что такое формат ОГЭ?
                    </p>
                    <div className="main__section__diagnostic__question__border"></div>
                    <div className="main__section__diagnostic__question__layout"></div>
                </div>
                <img src={studentImg} alt="img" className="main__section__diagnostic__teacherImg"/> 
            </div>
            
            {/* <div className="main__section__rates__title">
                Это структура и эффективные стратегии выполнения всех разделов ОГЭ:
            </div> */}
            {/* <div className="main__section__diagnostic__question">
                <div className="main__section__diagnostic__question__border"></div>
                <div className="main__section__diagnostic__question__layout"></div>
                <p className="main__section__diagnostic__answer__text" >
                    Это структура и эффективные стратегии выполнения всех разделов ОГЭ:
                </p>
            </div> */}
            {/* <img src={teacher} alt="img" className="main__section__diagnostic__teacherImg"/>  */}
        </div>
    )
}

















// function Diagnostic(){
//     return(
//         <div className="main__section__diagnostic">
//             <div className="main__section__diagnostic__question">
//                 <p className="main__section__diagnostic__question__text" >
//                     ОГЭ в 2024 году стартует уже 21 мая, готов ли ты к экзамену по английскому?
//                 </p>
//                 <div className="main__section__diagnostic__question__border"></div>
//                 <div className="main__section__diagnostic__question__layout"></div>
//             </div>
//             {/* <div className="main__section__diagnostic__answer__box"> */}
//                 <div className="main__section__diagnostic__answer">
//                     <div className="main__section__diagnostic__question__border"></div>
//                     <div className="main__section__diagnostic__question__layout"></div>
//                     <p className="main__section__diagnostic__answer__text" >
//                         Нет. Как узнать? 
//                     </p>
//                 </div>
//                 {/* <img src={student} alt="img" className="main__section__diagnostic__studentImg"/>  */}
//             {/* </div> */}
//             <div className="main__section__diagnostic__question">
//                 <div className="main__section__diagnostic__question__border"></div>
//                 <div className="main__section__diagnostic__question__layout"></div>
//                 <p className="main__section__diagnostic__answer__text" >
//                     Пройди диагностику и узнай свой уровень 
//                 </p>
//                 <div className="main__section__diagnostic__answer__buttonContainer">
//                     <a href="https://student.shineschool.ru/home/assessment/4630234?slug=DmN7W0AW&onlypreview=true" target="_blank">
//                         <button className="main__section__diagnostic__answer__buttonContainer__button">
//                             готов ли ты к ОГЭ?
//                         </button>
//                     </a>
//                 </div>
//             </div>
//             <div className="main__section__diagnostic__question">
//                 <p className="main__section__diagnostic__question__text" >
//                     Прошел тест? <br/> Если уровень достаточный, нужно просто понимать формат ОГЭ
//                 </p>
//                 <div className="main__section__diagnostic__question__border"></div>
//                 <div className="main__section__diagnostic__question__layout"></div>
//             </div>
//             <img src={teacher} alt="img" className="main__section__diagnostic__teacherImg"/> 
//         </div>
//     )
// }

export default Diagnostic;
