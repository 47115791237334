import React from "react";

import dialog5 from '../img/dialog5.svg'
import dialog6 from '../img/dialog6.svg'
import background from '../img/backgroundLines.svg'
import time from '../img/time.svg'


function Date(){
    return(
        <div className="main__section__date" id="timetable">
            {/* <img src={background} alt="img" className="main__section__date__background"/> */}
            <div className="main__section__date__format" >
                {/* <img src={dialog5} alt="img" className="main__section__date__format__box"/> */}
                {/* <div className="main__section__date__format__text"> */}
                <div className="main__section__diagnostic__question dateBubble">
                    {/* <p className="main__section__date__format__text__title" >
                        Формат проведения:
                    </p> */}
                    <div className="main__section__diagnostic__question__border"></div>
                    <div className="main__section__diagnostic__question__layout"></div>
                    <div className="main__section__date__format__text" >
                        <p className="main__section__date__format__text__title" >
                            {/* В каком формате проходят занятия? */}
                            Как проходят занятия?
                        </p>
                        <p className="main__section__date__format__text__p" >
                            Уроки проводятся онлайн на собственной платформе школы. Предполагают лекционную часть, выполнение интерактивных заданий, общение с преподавателем в видеоконференции в реальном времени, ответы на вопросы. Также есть поддержка в чате TG.
                        </p>
                    </div>
                </div>
            </div>
            <div className="main__section__diagnostic__answer dateBubble" >
                <div className="main__section__diagnostic__question__border"></div>
                <div className="main__section__diagnostic__question__layout"></div>
                <div className="main__section__date__time__text">
                    <p className="main__section__date__time__text__title" >
                        Когда проходят уроки?
                    </p>
                    {/* <p className="main__section__date__time__text__p" >
                        3 раза в неделю: <br/>
                        <span className="main__section__date__time__text__p__note" >
                            (Вт 16.30 мск, <br/> Чт 18.00 мск, <br/> Сб 14.00 мск),<br/>
                        </span>
                    </p> */}
                    <p className="main__section__date__time__text__p" >
                        3 раза в неделю:
                    </p>
                    <div className="main__section__date__time__text__notes">
                        <p className="main__section__date__time__text__notes__note">
                            <img src={time} alt="img" className="main__section__date__time__text__notes__note__img"/>
                            Вторник - 16.30 мск
                        </p>
                        <p className="main__section__date__time__text__notes__note">
                            <img src={time} alt="img" className="main__section__date__time__text__notes__note__img"/>
                            Четверг - 18.00 мск
                        </p>
                        <p className="main__section__date__time__text__notes__note">
                            <img src={time} alt="img" className="main__section__date__time__text__notes__note__img"/>
                            Суббота - 14.00 мск
                        </p>
                        <p className="main__section__date__time__text__notes__note" >
                            (записи тоже будут)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Date;