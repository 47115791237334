import React, { useState } from "react";

import { Minimal, Optimal, Premium } from "./RatesInfo";


function RatesCard() {
    const [activeRate, setActiveRate] = useState('minimal');

    const toggleRate = (rate) => {
        if (activeRate === rate) {
            setActiveRate(''); // Закрываем текущий тариф, если он уже открыт
        } else {
            setActiveRate(rate); // Открываем новый тариф
        }
    };

    return (
        <div className="main__section__rates__popUp hystmodal" id='ratesCard' aria-hidden="true">
            <div className="main__section__rates__popUp__modal hystmodal__wrap">
                <div className="main__section__rates__popUp__modal__content hystmodal__window window__teacher" role="dialog" aria-modal="true">
                    <button data-hystclose className="hystmodal__close">Закрыть</button>
                    <div className="main__section__rates__popUp__modal__content__container">
                        <div className="main__section__rates__popUp__modal__content__container__chooseRate">
                            <button 
                                onClick={() => toggleRate('minimal')}
                                className={`main__section__rates__popUp__modal__content__container__chooseRate__button ${activeRate === 'minimal' ? 'active' : ''}`}
                            >
                                Минимальный
                            </button>
                            <button 
                                onClick={() => toggleRate('optimal')}
                                className={`main__section__rates__popUp__modal__content__container__chooseRate__button ${activeRate === 'optimal' ? 'active' : ''}`}
                            >
                                Оптимальный
                            </button>
                            <button 
                                onClick={() => toggleRate('premium')}
                                className={`main__section__rates__popUp__modal__content__container__chooseRate__button ${activeRate === 'premium' ? 'active' : ''}`}
                            >
                                Премиум
                            </button>
                        </div>
                        <div className="main__section__rates__popUp__modal__content__container__info">
                            {activeRate === 'minimal' && 
                                <div className="main__section__rates__popUp__modal__content__container__info__rate">
                                    {Minimal.map((data, key) => {
                                        return (
                                            <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                    {data.title}
                                                </div>
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features">
                                                    {Object.values(data.info).map((info, index) => (
                                                        <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer">
                                                            <p className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text">
                                                                {info}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            );
                                        })}
                                </div>
                            }
                            {activeRate === 'optimal' && 
                                <div className="main__section__rates__popUp__modal__content__container__info__rate">
                                    {/* <p className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                        Все содержание пакета Минимальный + лексические и грамматические уроки:
                                    </p> */}
                                    {Minimal.map((data, key) => {
                                        return (
                                            <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                    {data.title}
                                                </div>
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features">
                                                    {Object.values(data.info).map((info, index) => (
                                                        <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer">
                                                            <p className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text">
                                                                {info}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            );
                                        })}
                                        {Optimal.map((data, key) => {
                                        return (
                                            <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                    {data.title}
                                                </div>
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes">
                                                    {Object.values(data.info).map((info, index) => (
                                                        <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer">
                                                            <p className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text">
                                                                {info}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            );
                                        })}
                                </div>
                            }
                            {activeRate === 'premium' && 
                                <div className="main__section__rates__popUp__modal__content__container__info__rate">
                                    {/* <p className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                        Программа для пакета Премиум:
                                    </p> */}
                                    {Minimal.map((data, key) => {
                                        return (
                                            <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                    {data.title}
                                                </div>
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features">
                                                    {Object.values(data.info).map((info, index) => (
                                                        <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer">
                                                            <p className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text">
                                                                {info}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            );
                                        })}
                                        {Optimal.map((data, key) => {
                                        return (
                                            <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                    {data.title}
                                                </div>
                                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes">
                                                    {Object.values(data.info).map((info, index) => (
                                                        <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer">
                                                            <p className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text">
                                                                {info}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            );
                                        })}
                                        {Premium.map((data, key) => {
                                            return (
                                                <div key={key} className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                                    <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                                        {data.title}
                                                    </div>
                                                    <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features">
                                                        {Object.values(data.info).map((info, index) => (
                                                            <div className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer">
                                                                <p className="main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text">
                                                                    {info}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            }
                            <div className="main__section__rates__popUp__modal__content__container__info__rate__box">
                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__title">
                                    Финальный тест
                                </div>
                                <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes">
                                    <div className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer">
                                        <p className="main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text">
                                            <div className="inline">Интерактивный тест на платформе</div> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RatesCard;


