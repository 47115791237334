import teacher1 from '../img/teachers/teacher-1.webp'
import teacher2 from '../img/teachers/teacher-2.webp'
import teacher3 from '../img/teachers/teacher-3.webp'
import teacher4 from '../img/teachers/teacher-4.webp'
import teacher5 from '../img/teachers/teacher-5.webp'
import teacher6 from '../img/teachers/teacher-6.webp'
import teacher7 from '../img/teachers/teacher-7.webp'



export const DevelopersInfo = [
    {
        id: 1,
        modal: 'teacher1',
        name: 'Кан Ирина Олеговна',
        img: teacher1,
        post: 'Aкадемический директор, методист онлайн школы «Shine School»',
        info: [
            'Я преподаю английский язык уже почти 20 лет и нежно люблю свою профессию. За это время я работала с учениками разного возраста и в разном формате: в вузе со студентами, с детьми от 5 лет в частной школе, с подростками и взрослым в языковых центрах и онлайн.', 
            'Мой профиль — подготовка к международным экзаменам и ЕГЭ. Мои ученики успешно сдают кембриджские экзамены (PET, FCE, Business English Certificate), а также ЕГЭ на 80+. Не передать словами мою радость, когда мои ученики добиваются таких высоких результатов!', 
            'Имею диплом преподавателя английского языка с отличием по специальности лингвистика, сертификаты международных экзаменов TOEFL, TOEIC С1(Advanced).',
            'Прошла курс обучения в языковой школе в Лондоне, участница American English E-Teacher Program. Прохожу международную сертификацию по методике преподавания TESOL (Arizona State University). Очень люблю учиться и учить онлайн и по опыту знаю, что каждый может достичь своей цели с помощью онлайн-обучения и открыть для себя мир без границ!',
        ]
    },
    {
        id: 2,
        modal: 'teacher2',
        name: 'Пухач Ирина Александровна',
        img: teacher2,
        post: 'Методист онлайн школы «Shine School»',
        info: [
            'Меня зовут Пухач Ирина и я преподаю английский язык уже почти 15 лет. За это время я работала с учениками разного возраста: от 7 до 70 лет, и в разном формате: в языковой школе офлайн и онлайн, а также с корпоративными клиентами ведущих компаний России.', 
            'Я специализируюсь на подготовке учеников к международным экзаменам (PET, FCE, CAE, IELTS, LCCI) и государственной итоговой аттестации (ОГЭ и ЕГЭ). Итогами моей работы стали успешная сдача экзаменов, подтверждённые высокие уровни учеников и поступление в престижные ВУЗы России.',
            'Имею диплом преподавателя двух иностранных языков Самарского Государственного Педагогического Университета и сертификаты международных экзаменов FCE и CAE.',
            'Помимо регулярной практики английского языка, всегда повышаю свой профессиональный уровень в сфере методики преподавания: имею международные сертификаты Teaching Knowledge Test: Modules 1, 2, 3; прошла курс Teaching English Online от Кембриджского университета и др.',
            'Мой подход к обучению можно описать так: «Английский – легко и по делу.» Подбираю индивидуальный подход к каждому ученику, чтобы они смогли достигнуть своих целей в краткие сроки.',
        ]
    },
];


export const TeacherInfo = [
    {
        id: 1,
        modal: 'teacher7',
        name: 'Кристина Вячеславовна',
        img: teacher7,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Кристина. На данный момент заканчиваю РГУ им. Есенина по специальности «Учитель иностранных языков». Преподаю английский детям и подросткам с 2021 года.',
            'Знание английского — это один из самых важных навыков в современном мире, поэтому изучение языка должно быть не только эффективным, но и интересным. Этому принципу я следую на своих занятиях, поэтому мои ученики никогда не скучают!', 
        ],
    },
    {
        id: 2,
        modal: 'teacher3',
        name: 'Елена Олеговна',
        img: teacher3,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Преподаю английский более 7 лет, как в России, так и за рубежом. Работала и стажировалась в Китае и Польше.', 
            'Имею несколько международных сертификатов от университета Кэмбриджа по методике преподавания:',
            '• Certificate in English Language Teaching – Secondary (CELT-S Cambridge)',
            '• Certificate in English Language Teaching – Primary (CELT-P Cambridge)',
            '• Teaching English Online (Cambridge Assessment English.)',
            '• Cambridge Teaching Knowledge Test (TKT Module 1-3).',
            '• Cambridge TKT: Content and language integrated learning',
            '• Cambridge TKT: Young learners',
            '• Cambridge CELTA (Pyatigorsk, Russia).',
            '• Certificate in Teaching English to the Speakers of Other Languages',
            '• Teaching English as a second or foreign language (TEFL)',
            'Всегда стараюсь, чтобы мои уроки были увлекательным и веселыми, моя цель — вдохновить учеников активно использовать английский в повседневной жизни и научить их уверенно общаться в любой ситуации.',
        ],
    },
    {
        id: 3,
        modal: 'teacher4',
        name: 'Екатерина Владимировна',
        img: teacher4,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'В 2007 год я закончила Казанский государственный гуманитарно-педагогический университет. Английский преподаю уже более 12 лет, 4 из которых я работала в Китае.', 
            'В 2023 году я получила Кембриджский сертификат САЕ, подтверждающий знание языка на уровне Advanced, и прошла курс по методике преподавания CELTA.',
            'Есть опыт обучения студентов с 7 до 60 лет, но особенно люблю работать с подростками и взрослыми. Особенно нравится работать со студентами, у которых есть конкретная цель, например, сдать экзамен, пройти собеседование или выступить с презентацией на профессиональную тему. Среди моих учеников есть поляки, турки, китайцы. Работая с иностранцами, каждый раз убеждаюсь, что учить английскому можно только на английском. И это не просто возможно, но и эффективно. Попробуем?',
        ]
    },
    {
        id: 4,
        modal: 'teacher5',
        name: 'Юлия Евгеньевна',
        img: teacher5,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'На занятиях создаю дружелюбную атмосферу и стараюсь заинтересовать ученика.', 
            'Преподаю английский больше 10 лет, в работе использую коммуникативный подход.',
            'В 2014 закончила Гуманитарный Университет в г. Екатеринбурге по специальности «Регионоведение», в 2017 аспирантуру по направлению «Политические науки».',
        ],
    },
    {
        id: 5,
        modal: 'teacher6',
        name: 'Марина Сергеевна',
        img: teacher6,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Марина Сергеевна, преподаю английский язык более трех лет детям и взрослым.', 
            'Окончила Алтайский государственный педагогический университет, лингвистический институт, по специальности «Педагогическое образование (с двумя профилями подготовки: Английский язык и Немецкий язык)».',
            'С удовольствием обучаю детей и взрослых английскому языку. Мои уроки проходят продуктивно и интересно. Я нахожу индивидуальный подход к каждому ученику и развиваю коммуникативные навыки общения на английском! Каждое занятие насыщенно интерактивными заданиями, благодаря которым изучение английского будет не только полезным, но и веселым и нескучным!',
            'Со мной Вы научитесь грамотной письменной и устной речи на английском, а также избавитесь от языкового барьера. See you in our amazing lessons!',
        ],
    },
];
