import React from "react";

import RatesCard from "./ratesCard";

import star1 from '../img/star1.svg'
import star2 from '../img/star2.svg'
import star3 from '../img/star3.svg'
import arrow1 from '../img/arrow1.svg'
import rateArrow1 from '../img/rateArrow1.svg'
import rateArrow2 from '../img/rateArrow2.svg'


import availability from '../img/availability.svg'


function Rates(){
    return(
        <div className="main__section__rates" id="rates">
            <div className="main__section__rates__title">
                Приглашаем на авторский интенсивный курс онлайн-школы Shine School <b>ОГЭ&nbsp;Turbo&nbsp;English</b> 
            </div>
            <div className="main__section__rates__container">
                <div className="main__section__rates__container__box __rate1">
                    <div className="main__section__rates__container__box__rect1">
                        <div className="main__section__rates__container__box__rect1__rect2">
                            <div className="main__section__rates__container__box__rect1__rect2__rect3">
                                {/* <img src={availability} alt="logo" className="notAvailable__img" /> */}
                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4 ">
                                    <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5">
                                        <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6">
                                            <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content">
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__p">
                                                    пакет
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title">
                                                    “Минимальный”
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__duration">
                                                    3 недели, 10 часов
                                                </p>
                                                <img src={star1} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__img" />
                                                <ul className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list">
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 лекционных уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 практических уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Домашние задания с проверкой
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Записи уроков
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Конспекты
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Чат в TG с ответами на вопросы
                                                    </li>
                                                </ul>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__price">
                                                    2000 рублей
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__note">
                                                </p>
                                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button" data-hystmodal='#form'>
                                                    <button className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover">
                                                        Предзапись на октябрь 2024
                                                    </button>
                                                </div>
                                                {/* <form action="https://securecardpayment.ru/sc/yCwkKsRZzERlkCPm" target="blank">
                                                    <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button">
                                                        <button className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover">
                                                            купить курс
                                                        </button>
                                                    </div>
                                                </form> */}
                                                <a href="#"
                                                    className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details"
                                                    data-hystmodal='#ratesCard'
                                                >
                                                    подробнее о программе курса
                                                    <img src={arrow1} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details__img" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img src={rateArrow1} alt="logo" className="rateArrow__first" /> */}
                <div className="main__section__rates__container__box __rate2">
                    <img src={rateArrow1} alt="logo" className="rateArrow__first" />
                    <div className="main__section__rates__container__box__rect1">
                        <div className="main__section__rates__container__box__rect1__rect2">
                            <div className="main__section__rates__container__box__rect1__rect2__rect3">
                                {/* <img src={availability} alt="logo" className="notAvailable__img" /> */}
                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4  ">
                                    <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5">
                                        <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6">
                                            <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content">
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__p">
                                                    пакет
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title">
                                                    “Оптимальный”
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__duration">
                                                    12 недель, 34 часа
                                                </p>
                                                <img src={star2} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__img" />
                                                <ul className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list">
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 лекционных уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 практических уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        12 практических уроков (по 60 минут) по лексике
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        12 практических уроков (по 60 минут) по грамматике
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Домашнее задание с проверкой по темам урока
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Записи уроков
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Конспекты
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        2 проверки выполнения устной и письменной части с развернутой обратной связью
                                                    </li>
                                                </ul>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__price">
                                                    6000 рублей
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__note">
                                                    (возможна оплата двумя частями 3000+3000)
                                                </p>
                                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button" data-hystmodal='#form'>
                                                    <button className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover">
                                                        Предзапись на октябрь 2024
                                                    </button>
                                                </div>
                                                <a href="#"
                                                    className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details"
                                                    // className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details notAvailable__btn"
                                                    data-hystmodal='#ratesCard'
                                                >
                                                    подробнее о программе курса
                                                    <img src={arrow1} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details__img" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={rateArrow2} alt="logo" className="rateArrow__second" />
                </div>
                {/* <img src={rateArrow2} alt="logo" className="rateArrow__second" /> */}
                <div className="main__section__rates__container__box __rate3">
                    <div className="main__section__rates__container__box__rect1">
                        <div className="main__section__rates__container__box__rect1__rect2">
                            <div className="main__section__rates__container__box__rect1__rect2__rect3">
                                {/* <img src={availability} alt="logo" className="notAvailable__img" /> */}
                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4 ">
                                    <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5">
                                        <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6">
                                            <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content">
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__p">
                                                    пакет
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title">
                                                    “Премиум”
                                                    <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title__note">
                                                        (индивидуальный)
                                                    </p>
                                                </p>
                                                
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__duration">
                                                    12 недель, 46 часов
                                                </p>
                                                <img src={star3} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__img" />
                                                <ul className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list">
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 лекционных уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        5 практических уроков (по 60 минут) по стратегиям выполнения всех 5 блоков ОГЭ
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        12 практических уроков (по 60 минут) по лексике
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        12 практических уроков (по 60 минут) по грамматике
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Домашнее задание с проверкой по темам урока
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Записи уроков
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        Конспекты
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        2 проверки выполнения устной и письменной части с развернутой обратной связью
                                                    </li>
                                                    <li className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item">
                                                        12 индивидуальных занятий с педагогом для отработки полученного материала
                                                    </li>
                                                </ul>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__price">
                                                    15 500 рублей
                                                </p>
                                                <p className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__note">
                                                    (возможна оплата двумя частями 3000+3000)
                                                </p>
                                                <div className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button" data-hystmodal='#form'>
                                                    <button className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover">
                                                        Предзапись на октябрь 2024
                                                    </button>
                                                </div>
                                                <a href="#"
                                                    className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details"
                                                    // className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details  notAvailable__btn"
                                                    data-hystmodal='#ratesCard'
                                                >
                                                    подробнее о программе курса
                                                    <img src={arrow1} alt="logo" className="main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details__img" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RatesCard/>
        </div>
    )
}

export default Rates;